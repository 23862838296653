<template>
  <div>
    <transition name="fade-three" mode="out-in">
      <router-view />
    </transition>
    <van-tabbar :border="false" v-model="active" ruote z-index='1000' safe-area-inset-bottom :class="theme">
      <van-tabbar-item replace :to="item.path" :name="item.id" v-for="item in tabBarArr" class="tab-item"
        :class="{ bigItem: item.routeName == $route.name }" :key="item.id">
        <template #icon="props">
          <!-- <img :src="require('@/assets/img/a0'+(item.id*1+1)+ (props.active&&theme!='light'?'-':'')+(!props.active&&theme=='light'?'-':'')+'.png')" /> -->
          <img :src="item.selected" v-if="props.active && theme == 'light'" />
          <img :src="item.dark" v-if="props.active && theme == 'dark'" />
          <img :src="item.normal" v-if="!props.active" />
        </template>
        {{ $t(item.name) }}
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: "tabbar",
  data() {
    return {
      show: true,
      tabBarArr: [
        {
          id: 0, //ID号
          name: "首页", //名称
          path: "/",
          routeName: "/", //路由名称
          normal: require("@/assets/img/a01--.png"), //未选中时图标
          dark: require("@/assets/img/a01-.png"), //选中时图标
          selected: require("@/assets/img/a01.png"), //选中时图标
        },
        // {
        //   id: 1, //ID号
        //   name: "市场", //名称
        //   path: "/shop",
        //   routeName: "shop", //路由名称
        //   normal: require("@/assets/img/a02--.png"), //未选中时图标
        //   dark: require("@/assets/img/a02-.png"), //选中时图标
        //   selected: require("@/assets/img/a02.png"), //选中时图标
        // },
        {
          id: 1, //ID号
          name: "商城", //名称
          path: "/mall",
          routeName: "mall", //路由名称
          normal: require("@/assets/img/a02--.png"), //未选中时图标
          dark: require("@/assets/img/a02-.png"), //选中时图标
          selected: require("@/assets/img/a02.png"), //选中时图标
        },
        {
          id: 2, //ID号
          name: "交易", //名称
          path: "/trading",
          routeName: "trading", //路由名称
          normal: require("@/assets/img/a03--.png"), //未选中时图标
          dark: require("@/assets/img/a03-.png"), //选中时图标
          selected: require("@/assets/img/a03.png"), //选中时图标
        },
        {
          id: 3, //ID号
          name: "我的", //名称
          path: "/mine",
          routeName: "mine", //路由名称
          normal: require("@/assets/img/a04--.png"), //未选中时图标
          dark: require("@/assets/img/a05-.png"), //选中时图标
          selected: require("@/assets/img/a04.png"), //选中时图标
        },
      ],
      isShowBottom: true, //显示或者隐藏footer
      documentHeight: document.documentElement.clientHeight, //默认屏幕高度
    }
  },
  computed: {
    active: {
      get: function () {
        let obj = this.tabBarArr.find(
          (val) => val.routeName == this.$route.name
        )
        return obj.id || 0
      },
      set: function (data) { },
    },
  },
  methods: {},
  mounted() {
    window.onresize = () => {
      return (() => {
        if (this.documentHeight > document.documentElement.clientHeight) {
          this.isShowBottom = false
        } else {
          this.isShowBottom = true
        }
      })()
    }
  },
}
</script>
<style lang="less" scoped>
//白昼模式
.light.van-tabbar {
  background: #fff;

  .van-tabbar-item {
    background: #fff;

    /deep/.van-tabbar-item__text {
      color: #a8a8a8;
    }
  }

  .van-tabbar-item--active {
    background: #fff;

    /deep/.van-tabbar-item__text {
      color: #EF834C;
    }
  }
}

//暗黑模式
.dark.van-tabbar {
  background: #242424;

  .van-tabbar-item {
    background: #242424;

    /deep/.van-tabbar-item__text {
      color: #a8a8a8;
    }
  }

  .van-tabbar-item--active {
    background: #242424;

    /deep/.van-tabbar-item__text {
      color: #EF834C !important;
    }
  }
}

.fade-three-enter-active,
.fade-three-leave-active {
  transition: opacity 0.15s;
}

.fade-three-enter,
.fade-three-leave-to

/* .fade-three-leave-active below version 2.1.8 */
  {
  opacity: 0;
}
</style>